<template>
  <div class="cabin-content">
    <router-view
      v-if="cabinUserRoleId"
      :key="$route.fullPath"
      :cabinUserRoleId="cabinUserRoleId"
      :cabin="cabin"
    ></router-view>
  </div>
  <TabBar></TabBar>
</template>
<script>

import TabBar from '@/components/TabBar';
import CabinService from '@/services/CabinService';
import { mapGetters } from 'vuex';

export default {
  components: {
    TabBar
  },
  data() {
    return {
      cabin: null,
      cabinUserRoleId: null
    };
  },
  computed: {
    ...mapGetters(['utilities/cabinRoles', 'users/userId'])
  },
  async created() {
    const { data } = await CabinService.getCabin(this.$route.params.cabinId);
    this.cabin = data;
    this.$store.commit('utilities/setStorageToken', this.cabin.storageTokenQuery)
    this.determineCabinUserRoleId();
  },
  methods: {
    determineCabinUserRoleId() {
      let userRole = this.cabin.cabinUserRoles.find(item => {
        return item.cabinUserId === this['users/userId'];
      });

      if(!userRole) {
        this.$router.push({ name: 'Cabins' });
      } else {
        this.cabinUserRoleId = userRole.cabinRoleId;
      }
    }
  }
};
</script>
