<template>
  <div class="tab-bar">
    <router-link
      class="tab-bar_item"
      v-for="(item, index) in menu"
      :to="{ name: item.routeName }"
      :key="index"
    >
      <Dash v-if="item.routeName === 'dashboard'" />
      <Hammer v-if="item.routeName === 'settings'" />
      <Scroll v-if="item.routeName === 'checklists'" />
      <Home v-if="item.routeName === 'calendar'" />
      <Book v-if="item.routeName === 'cabinfeed'" />
      {{ item.name }}
    </router-link>
  </div>
</template>
<script>
import Home from '@/assets/home.svg';
import Hammer from '@/assets/hammer.svg';
import Scroll from '@/assets/scroll.svg';
import Dash from '@/assets/hyttaDash.svg';
import Book from '@/assets/bookClosed.svg';

export default {
  name: 'TabBar',
  components: {
    Home,
    Hammer,
    Scroll,
    Dash,
    Book
  },
  data() {
    return {
      menu: [
        {
          name: 'Oversikt',
          routeName: 'dashboard'
        },
        {
          name: 'Kalender',
          routeName: 'calendar'
        },
        {
          name: 'Sjekklister',
          routeName: 'checklists'
        },
        {
          name: 'Hytteboka',
          routeName: 'cabinfeed'
        },
        {
          name: 'Innstillinger',
          routeName: 'settings'
        },
      ]
    };
  }
};
</script>
<style lang="scss" scoped>
.tab-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  z-index: 999;
  background-color: #DFEAE6;
  padding: .75rem 0 1.5rem 0;
  display: none;
  @media (max-width: $breakpoint-mobile) {
    display: flex;
  }
  &_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: black;
    font-weight: 500;
    font-size: 11px;
    svg {
      padding: 0.5rem;
      margin-bottom: 0.25rem;
      fill: black;
    }
  }
  &_item.router-link-active {
    color: $hytta;
    svg {
      fill: $hytta;
    }
  }
}
</style>
